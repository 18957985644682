.shrine-builders-container {
	height: 60vh;
	width: 100%;
	/* max-height: 800px;
	max-width: 1280px;
	min-height: 600px;
	min-width: 1000px; */
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
}

.shrine-builders-border {
	height: 40vh;
	width: 16vw;
	background: transparent;
	border-radius: 10px;
	transition: border 1s;
	position: relative;
}

.shrine-builders-border:hover {
	border: 1px solid white;
}

.shrine-builders-card {
	background: grey;
	border-radius: 10px;
	transition: background 0.8s;
	overflow: hidden;
	background: black;
	box-shadow: 0 70px 63px -60px #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.card0 {
	background: url("./../../../assets/shrine-builder-pics/Julius/Julius.jpeg")
		center center no-repeat;
	background-size: 24vw;
}

.card0:hover h2,
.card0:hover .fa {
	opacity: 1;
}

.card0:hover {
	background: url("./../../../assets/shrine-builder-pics/Julius/Julius.jpeg")
		left center no-repeat;
	background-size: 32vw;
}

.card1 {
	background: url("./../../../assets/shrine-builder-pics/Ruthvik/Ruthvik.jpeg")
		center center no-repeat;
	background-size: 20vw;
}

.card1:hover h2,
.card1:hover .fa {
	opacity: 1;
}

.card1:hover {
	background: url("./../../../assets/shrine-builder-pics/Ruthvik/Ruthvik.jpeg")
		left center no-repeat;
	background-size: 32vw;
}

.card2 {
	background: url("./../../../assets/shrine-builder-pics/Bharath/Bharath.jpeg")
		center center no-repeat;
	background-size: 20vw;
}

.card2:hover h2,
.card2:hover .fa {
	opacity: 1;
}

.card2:hover {
	background: url("./../../../assets/shrine-builder-pics/Bharath/Bharath.jpeg")
		left center no-repeat;
	background-size: 32vw;
}

.Torii-mate-name {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: white;
	margin-top: 30px;
	opacity: 0;
	transition: opacity 1s ease-in;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
}

.shrine-builders-container .fa {
	opacity: 0;
	transition: opacity 1s;
}

.shrine-builders-icons {
	position: absolute;
	fill: #fff;
	color: #fff;
	height: 80px;
	bottom: 40px;
	left: 15px;
	width: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.shrine-builders-icons > i {
	cursor: pointer;
}

.Torii-role {
	text-align: center;
	opacity: 0;
	transition: opacity 1s ease-in;
}

.shrine-builder:hover > .Torii-role {
	opacity: 1;
}
