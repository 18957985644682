.Header {
	display: block;
	overflow: hidden;
}

.shrine-head {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	margin: 3vh 5vw 3vh 5vw;
	/* background-color: green; */
}

.shrine-social {
	display: flex;
	gap: 2vw;
	width: 8vw;
	min-width: 200px;
}

.shrine-social-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10%;
}

.shrine-social-mobile img {
	width: 30px;
	height: 30px;
	object-fit: fill;
}

.shrine-social img {
	width: 30px;
	height: 30px;
	object-fit: fill;
}

.ToriiLogo {
	height: 70px;
	object-fit: contain;
}

.ToriiLogo-mobile {
	height: 70px;
	object-fit: contain;
	margin: 3vh auto;
}

.btn-shrine {
	background-color: #383e49;
	border: none;
	border-radius: 5px;
	height: 40px;
	width: 8vw;
	min-width: 200px;
	color: white;
	font-family: shrine;
	letter-spacing: 1px;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	cursor: pointer;
}

/* #exitshrine{
    display: none;
} */
