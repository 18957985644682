/* .section-tagline {
    padding: 10vw;
    height: 100%;
    min-height: 500vh;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: visible;
  } */

/* * {
	box-sizing: border-box;
} */

.section-tagline h1 {
	font-weight: 800;
    margin: auto;
    text-align: center;
    letter-spacing: 0.2vw;
    position: absolute;
    top: 45vh;
    left: 42vw;
    width: 16vw;
    z-index: 1;
    /* mix-blend-mode: difference; */
    display: none;
}

.gallery {
	/* background-color: #1c1b1b; */
	position: relative;
	height: 60vh;
	width: 92%;
	margin: 8vh auto 0 auto;

	display: -webkit-flex; /* Safari */
	display: flex;
	flex-direction: row;
	justify-content: center;
    /* gap: 0.vw; */

	overflow: hidden;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

/* .gallery {
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 60vh;
	width: 88%;
    margin: 8vh auto 0 auto;
	position: absolute;
	top: ;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;

	@media (max-width: 768px) {
		width: 160%;
	}
} */

.col {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	align-self: flex-start;
	justify-self: flex-start;
}

.col:nth-child(2) {
	align-self: flex-end;
	justify-self: flex-end;
}

.image {
	width: 90%;
	/* filter: saturate(0); */
	padding-left: 1vw;
    padding-top: 2vh;
}

/* .image:hover {
	filter: saturate(1);
} */

.gallery img {
	transition: 0.3s ease-out;
	overflow: hidden;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
		0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 22.3px 17.9px rgba(0, 0, 0, 0.072),
		0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
	width: 100%;
}
