* {
	color: white;
}

.CoursesContainer {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.CoursesContainer > h1 {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 39px;
	color: white;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
}

.CoursesContainer > pre {
	text-align: center;
	color: white;
	font-weight: 400;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 1.8;
	margin: 30px;
}

.coursentool {
	/* display: grid;
	grid-template-columns: auto auto auto; */
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	padding: 0px calc((100% - (553px * 3)) / 2);
}

.course {
	margin: 40px 100px 50px 100px;
	height: 594px;
	width: 353px;
	background: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 5px 45px rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	cursor: pointer;
	filter: blur(0px);
}

.course_blocked {
	filter: blur(0px);
}

.course-tier {
	position: absolute;
	top: 20px;
	left: -10px;
	background-color: #ea6868;
	padding: 5px 7px;
	border-radius: 2px;
	font-weight: bold;
	color: #333;
	min-width: 25%;
	text-align: center;
	box-sizing: border-box;
	box-shadow: 0px 5px 45px rgba(255, 255, 255, 0.2);
}

.course-price {
	font-family: Inter;
	font-size: small;
	color: #4f4f4f;
	position: relative;
	font-weight: 600;
	margin-left: 2px;
	font-size: 1.1vh;
}

.course-price::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 110%;
	height: 1px;
	background: #4f4f4f;
	transform: rotate(-20deg);
}

.course-img {
	width: 100%; /* or any custom size */
	height: 35%;
	object-fit: fill;
}

.course-info {
	background-color: white;
	/* padding: 10px; */
}

.course-title {
	width: 262px;
	height: 52px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;

	color: #373f46;

	padding-left: 30px;
}

.course-description {
	padding: 0px 30px;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 29px;
	/* or 180% */

	color: #55636f;
	text-overflow: ellipsis;
}

hr {
	margin: 0 160px;
	border-color: rgba(255, 255, 255, 0.6);
}

.CourseMenu_navBar {
	color: white;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 39px;
	margin: 0 160px;
	display: inline;
}

.CourseMenu_navBar > div {
	color: rgba(255, 255, 255, 0.6);
	text-align: center;
	width: 200px;
	display: inline-block;
	cursor: pointer;
}

.CourseMenu_navBar > div:not(.CourseMenu_selected):hover {
	color: rgba(255, 255, 255, 0.8);
	font-size: 22px;
	transition: font-size 0.3s;
}

.CourseMenu_navBar > span {
	color: rgba(255, 255, 255, 0.6);
}

.CourseMenu_selected {
	font-size: 32px;
	color: white !important;
}

.loading-cards {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	padding: 0px calc((100% - (553px * 3)) / 2);
}

.card-is-loading {
	margin: 40px 100px 50px 100px;
	height: 594px;
	width: 353px;
	background: #111;
	box-sizing: border-box;
	/* box-shadow: 0px 5px 45px rgba(255, 255, 255, 0.2); */
	border-radius: 5px;
	filter: blur(0px);
	overflow: hidden;
}

.card-is-loading .image,
.card-is-loading h3,
.card-is-loading p {
	background: #111;
	background: linear-gradient(110deg, #121212 8%, #050505 18%, #121212 33%);
	border-radius: 5px;
	background-size: 200% 100%;
	animation: 1.5s shine linear infinite;
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

.card-is-loading .image {
	width: 100%; /* or any custom size */
	height: 35%;
}

.content {
	background-color: #111;
	/* padding: 20px; */
	border-radius: 5px;
}

.card-is-loading h3 {
	width: auto;
	height: 52px;
	margin: 20px 30px;
}

.card-is-loading p {
	height: 250px;
	width: auto;
	margin: 0px 30px 30px 30px;
}
