.landing-pre-content-container{
    /* background-color: aliceblue; */
    /* position: relative; */
    /* top: 14vh; */
    height: 46vh;
	min-height: 300px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
	overflow: hidden;
}

.landing-pre-content{
	position: relative;
}

.landing-pre-content > img {
	/* width: 100%; */
	width: 80%;
	object-fit: contain;
	position: absolute;
	opacity: 0;
	margin: 25% 10%;
}

#pre_cont_img_0 {
	opacity: 1;
}

#pre_cont_img_1,
#pre_cont_img_2,
#pre_cont_img_3,
#pre_cont_img_5 {
	z-index: 2;
}

#pre_cont_img_4 {
	z-index: 1;
}

#pre_cont_img_6 {
	z-index: 3;
	opacity: 1;
}
