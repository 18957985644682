.landing-roadmap {
	width: 50vw;
	margin: auto;
	height: 88vh;
	position: relative;
}


.landing-roadmap img {
	z-index: 0;
	margin: auto;
	display: block;
	position: absolute;
	top: 10vh;
	left: 10vw;
	width: 30vw;
}

.landing-roadmap p {
	font-family: shrine;
	text-align: center;
	letter-spacing: 0.5vw;
	position: absolute;
	top: 55vh;
	left: 10vw;
	width: 30vw;
    font-size: 1.5vw;
}
