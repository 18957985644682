* {
	color: white;
}

.white {
	color: white;
}

.comp_container {
	display: flex;
	flex-direction: column;
	margin: 20px 180px;
}

.location {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	/* line-height: 24px; */

	color: #418bba;

	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .location > div {
	display: inline;
	color: #418bba;
	cursor: pointer;
} */

/* .location > div:hover {
	transform: translate(0, 100) rotate(-90deg);
	transform-origin: left center; 
	font-weight: 900;
	background-color: white;
} */

.nav-pop {
	display: inline-block;
	color: #418bba;
	cursor: pointer;
}

.nav-pop:hover {
	display: inline-block;
	transform: scale(1.02);
	margin-right: 5px;
	transform-origin: left center; 
	font-weight: 900;
}

.comp-title {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 3vh;
	/* line-height: 39px; */
	/* identical to box height */

	text-align: center;
	margin: 4vh auto;

	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.No-access-buy-link {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	background-color: rgba(255, 0, 0, 0.4);
	text-align: center;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	margin: 0 0 33px 0;
	padding: 1vh;
}

.No-access {
	cursor: url(./../../assets/blocked.svg), auto !important;
}

.comp-course-desc {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comp-course-img {
	/* width: 713px; */
	object-fit: contain;
	height: 30vh;
	/* height: 401.95px; */
}

.comp-desc-cont {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 30vh;
	min-width: 50%;
	padding-left: 3vw;
}

.comp-course-description {
	/* width: 625px; */
	width: fit-content;
	/* height: 144px; */

	font-family: "Open Sans";
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	/* line-height: 29px; */
	/* or 120% */

	display: flex;
	align-items: center;
	letter-spacing: 0.1em;
	align-self: center;
}

.clickformore {
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	/* line-height: 26px; */
	text-decoration: none;
	/* identical to box height, or 130% */
	color: #2980b9;
}

.downloadtools {
	margin: 91px 0 54px 0;
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	font-weight: 100;
	font-size: 32px;
	line-height: 26px;
	/* identical to box height, or 81% */
}

.downloadtools > span > a {
	font-weight: 700;
	color: #2980b9;
	text-decoration: none;
}

.course-content-heading {
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 26px;
	margin-bottom: 25px;
}

.course-module {
	background: #404040;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	padding: 0 57px 0 57px;
	margin: 25px 0;
	min-width: 800px;
	cursor: pointer;
}

.module-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	/* line-height: 36px; */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 100px;
}

.module-end {
	display: flex;
	justify-content: space-between;
	gap: 50px;
	line-height: 26px;
}

.module-lessons,
.lesson {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	/* line-height: 26px; */
}

.module-ext {
	position: relative;
	font-size: 56px;
	font-weight: 300;
	bottom: 5px;
}

.lesson {
	background: rgba(54, 54, 54, 0.38);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	margin: 0 30px 16px 30px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 42px 0 42px;
	cursor: pointer;
	/* display: none; */
}

.lesson-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 100px;
}

.comp-course-loading{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comp-course-loading .image{
	height: 30vh;
	width: 37%;
}

.comp-course-loading .desc{
	height: 30vh;
	min-width: 63%;
}

.comp-course-loading p{
	width: 90%;
	height: 60%;
	margin: 7% auto;
}

.comp-course-loading .image,
.comp-course-loading p,
.course-module.loading{
	background: #000;
	/* background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%); */
	background: linear-gradient(110deg, #121212 8%, #050505 18%, #121212 33%);
	/* border-radius: 5px; */
	background-size: 200% 100%;
	animation: 1.5s shine linear infinite;
}

.course-module.loading{
	margin: 10px 0;
}