* {
	color: white;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.5);
	/* box-shadow: 0 0 1px rgba(0, 0, 0, 0.5); */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.sidebar {
	height: 100%;
	min-width: 5vw;
	width: 20%;
	border-right: 1px solid white;
	overflow: hidden;
}

.closeSideBar {
	height: 100%;
	width: 5vw;
	border-right: 1px solid white;
	overflow: hidden;
	animation: closebar 0.5s linear;
}

@keyframes closebar {
	from {
		width: 20%;
	}
	to {
		width: 5vw;
	}
}

.openSideBar {
	height: 100%;
	width: 20%;
	border-right: 1px solid white;
	overflow: hidden;
	animation: openbar 0.5s linear;
}

@keyframes openbar {
	from {
		width: 5vw;
	}
	to {
		width: 20%;
	}
}

.mediaContainer {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	height: 75vh;
	margin-top: 60px;
}

.sidebartitle {
	font-family: shrine;
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 26px;
	text-align: center;
	width: 80%;
	margin-left: 5%;
	display: inline-block;
}

.closeSideBar ul,
.closeSideBar .horzln {
	display: none;
}

.closeSideBar .sidebarheader {
	display: flex;
	height: min-content;
	width: max-content;
	position: relative;
	/* top: 30px; */
	/* left: -50%; */
	/* vertical-align: bottom; */
	transform: translate(-25%, 200%) rotate(-90deg);
}

.closeSideBar .sidebartitle {
	width: min-content;
	margin: 10px;
}

.closeSideBar .toggler {
	display: inline-block;
	/* background-color: blue; */
	transform: rotate(90deg);
}

.toggler {
	/* display: inline-block; */
	/* margin-right: 0; */
	font-size: 24px;
	cursor: pointer;
}

.horzln {
	width: 100%;
	height: 0px;
	border-bottom: 1px solid white;
	margin: 20px 0;
}

.lessonVideoholder {
	margin: 0 4vw;
	height: 100%;
	flex: 3;
}

.applyflexbet {
	display: inline-block;
	/* justify-content: space-between; */
	margin-top: 4vh;
	margin-bottom: 35px;
	width: 100%;
	/* align-items: center; */
}

.lessonName {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 36px;
	line-height: 44px;
	display: inline-block;
	width: 50vw;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.linkcolor {
	color: #2980b9;
	text-decoration: none;
}

.sidebarModuleList {
	padding-left: 20px;
	margin-top: 0;
	height: 94%;
	overflow: hidden;
}

.sidebarModuleList:hover {
	overflow: scroll;
}

.sidebarModuleList:hover > li {
	margin-right: 15px;
}

.sidebarModuleList > li li {
	padding-left: 20px;
}

.sidebarModuleList > li {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 26px;
	text-overflow: ellipsis;
	/* white-space: nowrap; */
	overflow: hidden;
	margin-right: 25px;
	margin-bottom: 8px;
}

.sidebarModuleList > li:first-child {
	margin-bottom: 12px;
}

.sublist {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: small;
	line-height: 26px;
	list-style-type: none;
	padding: 15px 0;
}

.sublist > li {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
}

.sublist > li > p {
	padding: 0;
	margin: 0;
}

.sublist > li:not(.active-lesson):hover {
	transform: scale(1.1);
	transform-origin: left center;
	font-weight: 600;
	white-space: normal;
	margin-right: 20px;
}

.active-lesson {
	background-color: grey;
	font-weight: 600;
	border-radius: 10px;
	white-space: normal !important;
}

abbr {
	text-decoration: none;
}

/* .active-lesson:hover {
	white-space: normal;
} */

.iframeContainer {
	height: 85%;
}

.video {
	width: 100%;
}

.PrevNextCont {
	display: block;
	width: 16vw;
	/* background-color: #2980b9; */
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	float: right;
	min-width: 300px;
	margin-right: 3vw;
}

.PrevNextCont span {
	font-size: 28px;
	line-height: 39px;
	position: relative;
	top: 2px;
}

.next {
	float: right;
}

.prev,
.next {
	cursor: pointer;
}

.prev:hover,
.next:hover {
	font-weight: 900;
}

.location_width {
	width: 70vw;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* .mod-loading{
} */

.mod-title-loading{
	min-width: 70%;
	height: 3vh;
	border-radius: 10px;
}

.video-player-loading{
	width: 90%;
	height: 85%;
	margin: auto;
}

.video-player-loading,
.mod-title-loading,
.mod-loading li{
	background: #000;
	background: linear-gradient(110deg, #121212 8%, #050505 18%, #121212 33%);
	background-size: 200% 100%;
	animation: 1.5s shine linear infinite;
}

.mod-loading li{
	margin: 5px;
	width: 90%;
	height: 2.5vh;
	border-radius: 10px;
}

