.landing-page {
	/* margin-left: auto;
	margin-right: auto;
	display: block; */
	display: flex;
	flex-direction: row;
}

.landing-content {
	/* background-color: lightcyan; */
	/* margin-top: 12vh; */
	flex: 0.64;
	height: 88vh;
	/* padding: 0 7vw; */
}

.flex-dummy {
	flex: 0.18;
}

.landing-content hr {
	width: 40%;
	margin: auto;
}

.landing-sections {
	padding-top: 12vh;
	height: 88vh;
	overflow: hidden;
	width: 60vw;
	margin: auto;
}

.static-header {
	position: fixed;
	width: 100%;
	max-height: 12vh;
	top: 0;
	background-color: black;
	z-index: 100;
	/* background-color:aqua; */
}

.static-landing-sidebar-left {
	/* background-color: whitesmoke; */
	position: fixed;
	z-index: 1;
	overflow-x: hidden;
	width: 18vw;
	top: 27vh;
	height: 46vh;
	left: 0;
	min-height: 500px;
	/* border-right: 1px rgba(255, 255, 255, 0.3) solid; */
}

.static-landing-sidebar-right {
	position: fixed;
	z-index: 1;
	overflow-x: hidden;
	width: 18vw;
	height: 46vh;
	top: 27vh;
	right: 0;
	/* background-color: whitesmoke; */
}

.landing-nav-container {
	/* background-color: lightgray; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.landing-nav {
	border-left: 1px rgba(255, 255, 255, 0.3) solid;
	text-align: center;
	list-style-type: none;
	padding: 0;
	margin: 0;
	font-family: shrine;
	font-style: italic;
	font-size: 16;
	/* background-color: brown; */
	width: 100%;
	padding-top: 10vh;
	padding-bottom: 10vh;
}

.landing-nav-dummy {
	flex: 0.1;
}

.landing-nav > li {
	padding: 15px;
	letter-spacing: 2px;
	opacity: 0.4;
	cursor: pointer;
}

.landing-nav a {
	text-decoration: none;
}

.selected-landing-nav {
	/* background-color: bisque; */
	font-size: x-large;
	opacity: 1 !important;
}

.selected-landing-nav > a {
	color: blue;
}

.landing-content h1 {
	font-family: shrine;
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 39px;
	color: white;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
}

.shrinehome-img {
	display: block;
	height: 65vh;
	margin: auto;
}

.landing-content pre {
	text-align: center;
	color: white;
	font-weight: 400;
	font-family: shrine;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 1.8;
	margin: 30px;
}

#connect-msg {
	width: 100%;
	text-align: center;
	font-family: shrine;
}

.mobile-display {
	text-align: center;
}

.mobile-display h2{
	font-family: shrine;
}

.mobile-display p{
	width: 80%;
	margin: auto;
}

.mobile-display > .use-desktop-alert{
	color: #BE4F4F;
	text-align: center;
	margin-top: 10%;
}

.shrine-home-mobile{
	width: 70%;
	margin: 5% auto;
}

.separator-about-mobile{
	margin: 10% auto;
	width: 50%;
	border: 1px solid #333;
}

.ToriiTeammate-mob{
	width: 200px;
	height: 200px;
	object-fit: cover;
	border-radius: 150px;
	margin: 10% auto 0 auto;
}

.Team h2:not(:first-child){
	font-family: 'Inter';
	margin-bottom: 3px;
}

.Team {
	margin-bottom: 100px;
}

.Team p{
	color: gray;
}