.landing-about-page {
	font-family: shrine;
    position: relative;
    width: 90%;
    margin: 5vh auto 0 auto;
}

.landing-about-page > h1{
    text-align: center;
}

.landing-about-page > p{
    font-family: "Montserrat";
    text-align: center;
    margin-bottom: 5vh;
    font-weight: 500;
    font-size: 2vh;
}