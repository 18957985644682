.holder-tiers {
	font-family: shrine;
}

.holder-tiers-container {
	background-color: #1c1b1b;
	position: relative;
	height: 60vh;
	width: 92%;
	margin: 2vh auto 4vh auto;
	display: -webkit-flex; /* Safari */
	display: flex;
	overflow: hidden;
	border-radius: 20px;
}

.holder-tier-slide {
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	flex-grow: 1;
	cursor: pointer;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.holder-tier-slide-title {
	opacity: 0.6;
	color: #f1f1f1;
	position: relative;
	font-size: 2.5vw;
	margin: auto;
	text-align: center;
	transform: rotate(-90deg);
	white-space: no-wrap;
	top: 45%;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

.holder-tier-slide-content {
	opacity: 0;
	color: #f1f1f1;
	position: relative;
	width: 70%;
	font-size: 1vw;
	padding: 0;
	top: 20%;
	/* border: 2px solid #f1f1f1; */
	border-radius: 10px;
	line-height: 1.3;
	margin: auto;
	text-align: left;
	transform: rotate(0deg);
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

/* .flex-slide:not(:last-child){
    border-right: 1px solid #c5c5c5;
    
} */

.holder-tier-slide:not(:first-child)::before {
	content: "";
	position: absolute;
	top: 20%;
	bottom: 20%;
	border-right: 1px solid #c5c5c5;
}

.holder-tier-slide:hover {
	-webkit-flex-grow: 3;
	flex-grow: 3;
}

.selected-slide {
	-webkit-flex-grow: 3;
	flex-grow: 3;
}

.selected-slide > .holder-tier-slide-title,
.holder-tier-slide:hover > .holder-tier-slide-title {
	opacity: 1;
	top: 10%;
	transform: rotate(0deg);
}

.selected-slide > .holder-tier-slide-content,
.holder-tier-slide:hover > .holder-tier-slide-content {
	opacity: 1;
}

@keyframes homeFlextitle {
	0% {
		transform: rotate(90deg);
		top: 15%;
	}
	50% {
		transform: rotate(0deg);
		top: 15%;
	}
	100% {
		transform: rotate(90deg);
		top: 15%;
	}
}

.holder-tiers-selectors-container {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	justify-content: space-around;
	align-items: baseline;
}

.holder-tiers-selector {
	text-align: center;
	width: 8vw;
	border-bottom: 1px solid rgba(255, 255, 255, 0.6);
	padding: 0.25vh 0.25vw;
	border-radius: 10px;
	cursor: pointer;
}

.holder-tiers-selector-active {
	background-color: rgba(217, 217, 217, 0.2);
	border-bottom-color: white;
}

.holder-tiers-selector-active > div {
	font-size: xx-large !important;
	color: white !important;
}

.holder-tiers-selector > div {
	display: inline;
	font-size: x-large;
	font-weight: bold;
	color: rgba(255, 255, 255, 0.6);
}

.holder-tiers-selector-active > .toriiSym {
	font-size: x-large !important;
}

.holder-tiers-selector > .toriiSym {
	font-size: medium;
}

/* .slide-content-list1{
	background-color: #e5e5e5;
} */

.holder-tier-slide-content{
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 600;
	cursor: default;
}

.holder-tier-slide-content-list1 > li {
	font-size: 0.75vw;
	font-style: italic;
	letter-spacing: 0.05vw;
	line-height: 2;
	list-style-type: none;
	margin-left: -2vw;
}

.holder-tier-slide-content-list1 > li li,
.holder-tier-slide-list-items > li {
	font-size: 0.7vw;
	font-style: italic;
	letter-spacing: 0.05vw;
	opacity: 0.7;
	line-height: 2;
	margin-left: -2vw;
}

.comsoon {
	margin-left: 1vw;
}

.lower-tier-slide {
	text-decoration: line-through;
	opacity: 0.5;
}

.tier-monetory {
	font-family: Inter;
	font-size: small;
	/* color: #4f4f4f; */
	color: rgba(255, 255, 255, 0.6);
	position: relative;
	font-weight: 600;
	margin-left: 7%;
	font-size: small;
}

.holder-tiers-selector-active > .tier-monetory{
	font-size: medium;
}

.tier-monetory::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 110%;
	height: 1px;
	background: rgba(255, 255, 255, 0.6);;
	transform: rotate(-20deg);
}